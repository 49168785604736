import React from 'react';
import { Grid, Typography } from '@mui/material';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  selectedTab: number;
  value: number;
  route: string;
  onSelectTab: (value: number) => unknown;
  label: string;
}
const tabRouteMap = new Map<string, number>([
  ["/processes", 0], ["/process-runs/active", 1], ["/process-runs/scheduled", 2],
  ["/process-runs/finished", 3], ["/analytics", 4], ["/admin/processes", 0],["/admin/organizations", 1],["/admin/users", 2]
])

const TopBarTab = ({selectedTab, value, route, onSelectTab, label}: Props) => {
  const router = useRouter();
  const currentRoute = router.asPath.toString();
  let tabNumber = undefined;
  tabRouteMap.forEach(function(value, key) {
    if (currentRoute.includes(key)) tabNumber = value;
  });
  const tabRouteSelected = tabNumber ? tabNumber : selectedTab;
  return (
    <NextLink href={route} passHref legacyBehavior>
    <Grid
      item
      xs={true}
      sx={{
        background: tabRouteSelected === value ? "white" : undefined,
      }}
      className="optionTab"
      onClick={() => {
        onSelectTab(value)}}
    >
      <Typography color="primary" variant='h3' noWrap>{label}</Typography>
    </Grid>
    </NextLink>
  )
}

export default TopBarTab
