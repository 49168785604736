import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { IUser } from '@/interfaces/user';

export interface UserState {
  isSessionValid: boolean;
  isLoggedIn: boolean;
  isPending: boolean;
  user: IUser | null;
  fullScreenLoading: boolean;
  homeTab: number;
  drawerMenuOpen: boolean;
  videoModalURL?: string;
}

export const initialState: UserState = {
  isSessionValid: false,
  isLoggedIn: false,
  isPending: false,
  user: null,
  fullScreenLoading: false,
  homeTab: 0,
  drawerMenuOpen: false
};

export class UserReducer extends ImmerReducer<UserState> {
  public setIsLoggedIn(isLoggedIn: boolean) {
    this.draftState.isLoggedIn = isLoggedIn;
  }

  public setIsSessionValid(isSessionValid: boolean) {
    this.draftState.isSessionValid = isSessionValid;
  }

  public setIsPending(isLoading: boolean) {
    this.draftState.isPending = isLoading;
  }

  public setFullScreenLoading(loading: boolean) {
    this.draftState.fullScreenLoading = loading;
  }

  public setUser(user: IUser | null) {
    this.draftState.user = user;
  }

  public setHomeTab(tab: number) {
    this.draftState.homeTab = tab;
  }

  public setVideoModalURL(url?: string) {
    this.draftState.videoModalURL = url;
  }

  public setDrawerMenuOpen(open?: boolean) {
    this.draftState.drawerMenuOpen = open ?? !this.draftState.drawerMenuOpen;
  }
}

export default createReducerFunction(UserReducer, initialState);
