import React from 'react';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { empower } from '@/theme/empower';

import { Provider } from 'react-redux';
import store from '@/store';
import { restoreAuthenticateAsync } from '@/store/actions/authenticate';
import Script from 'next/script';
import Head from 'next/head';
import formatTitle from '@/utils/page-title';
import '@/styles/GlobalStyles.scss'
import Layout from '@/components/Layout';
import { AppPropsWithLayout } from '@/@types/next';
import * as bugsnag from '@/adapters/bugsnag';

bugsnag.start();
store.dispatch<any>(restoreAuthenticateAsync());

const App: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? ((page) => page);
  const ErrorBoundary = bugsnag.getErrorBoundaryComponent() ?? React.Fragment;

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Head>
          <title>{formatTitle("Thoughtful")}</title>
          <meta name="description" content="Thoughtful" />
        </Head>
        {process.env.NEXT_PUBLIC_HEAP_APP_ID &&
          <Script id="heap">
            {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
                heap.load(${process.env.NEXT_PUBLIC_HEAP_APP_ID});`}
          </Script>
        }
        <ThemeProvider theme={empower}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <Layout {...pageProps}>
              {getLayout(<Component {...pageProps} />)}
            </Layout>
          </StyledEngineProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
