import { createReducerFunction, ImmerReducer } from 'immer-reducer';

export interface DeleteScheduledRunState {
  isPending: boolean;
  isRejected: boolean;
  scheduleName: null | string;
}

const initialState: DeleteScheduledRunState = {
  isPending: false,
  isRejected: false,
  scheduleName: null,
};

export class DeleteScheduledRunReducer extends ImmerReducer<DeleteScheduledRunState> {

  public setIsPending() {
    this.draftState.isPending = true;
    this.draftState.isRejected = false;
  }

  public setIsRejected() {
    this.draftState.isPending = false;
    this.draftState.isRejected = true;
  }

  public setScheduleName(scheduleName: string) {
    this.draftState.scheduleName = scheduleName;
  }

  public reset() {
    this.draftState = initialState;
  }
}

export default createReducerFunction(DeleteScheduledRunReducer, initialState);
