import { IOrganization } from '@/interfaces/organization';
import { IProcess } from '@/interfaces/process';
import { IUser } from '@/interfaces/user';
import { createReducerFunction, ImmerReducer } from 'immer-reducer';

export interface IUsersState {
  isPending: boolean;
  isRefreshing: boolean;
  isRejected: boolean;
  users: IUser[];
}

const initialState: IUsersState = {
  isRefreshing: false,
  isPending: true,
  isRejected: false,
  users: [],
};

export class UsersReducer extends ImmerReducer<IUsersState> {
  public setIsPending() {
    this.draftState.isRefreshing = true;
    this.draftState.isRejected = false;
  }

  public setUsers(users: IUser[]) {
    this.draftState.isPending = false;
    this.draftState.isRefreshing = false;
    this.draftState.users = users;
  }

  public setIsRejected() {
    this.draftState.isPending = false;
    this.draftState.isRejected = true;
  }
}

export default createReducerFunction(UsersReducer, initialState);