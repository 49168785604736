import { IAnalyticsDashboard, IAnalyticsDigestSchedule, ICreateAnalyticsDigestSchedule, IOrganization } from '@/interfaces/organization';
import { IProcess, ProcessDto, UpdateProcessDto } from '@/interfaces/process';
import { IUser, IUserCreateBody, IUpdateUserBody } from '@/interfaces/user';
import HttpClientProtected from '../HttpClientProtected';
import {
  CreateScheduledRunResponse,
  IGetMeResponse,
  IGetAvailableProcessResponse,
  IGetScheduledProcessesResponse,
  IStartProcessBody,
  IStartProcessResponse,
  IGetActiveProcessesResponse,
  IGetFinishedProcessesQueries,
  IGetFinishedProcessesResponse,
  IGetFinishedProcessesFiltersResponse,
  IGetUserProcessResponse,
  IDownloadUrlBody,
  IDownloadUrlResponse,
  IEmptyResponse,
  ScheduledRunCreateBody,
  IGetFinishedProcessRunsResponse,
  IProcessRunLogsPage
} from './types';

class MainApiProtected extends HttpClientProtected {
  private static instanceCached: MainApiProtected;

  private constructor() {
    super(process.env.BASE_URL);
  }

  static getInstance = () => {
    if (!MainApiProtected.instanceCached) {
      MainApiProtected.instanceCached = new MainApiProtected();
    }

    return MainApiProtected.instanceCached;
  };

  public getMe = () => this.instance.get<IGetMeResponse>('/me');

  public getAvailableProcesses = () => (
    this.instance.get<IGetAvailableProcessResponse>('/available-processes-with-meta'));

  public createScheduledRun = (body: ScheduledRunCreateBody) => {
    return this.instance.post<CreateScheduledRunResponse>('/scheduled-runs', body)
  };

  public getScheduledProcessRuns = () => (
    this.instance.get<IGetScheduledProcessesResponse>('/processes/schedules')
  );

  public deleteScheduledRun = (name: string) => (
    this.instance.post<IEmptyResponse>(`/scheduled-runs/delete/${name}`)
  );

  public async getOrganizations() {
    return await this.instance.get<{ organizations: IOrganization[] }>('/organizations')
  }

  public async getUsers(): Promise<{ users: IUser[] }> {
    return this.instance.get('/users');
  }

  public getUser = (id: number) => (
    this.instance.get<{ user: IUser }>('/user/' + id));

  public updateUser = (id: number, body: Partial<IUpdateUserBody>) => (
    this.instance.patch<{ user: IUser }>('/user/' + id, body));

  public async unlockUser(id: number) {
    return await this.instance.post<IUser>(`/users/${id}/unlock`);
  }

  public async updateScheduleRunState(processId: number, scheduledRunId: number, enabled: boolean) {
    const payload = { enabled };
    return await this.instance.post(`/processes/${processId}/schedules/${scheduledRunId}/state`, payload);
  }

  public startProcess = (id: number, body?: IStartProcessBody) => (
    this.instance.post<IStartProcessResponse>(`/processes/start/${id}`, body));

  public getProcess = (id: number) => (
    this.instance.get<ProcessDto>(`/processes/${id}`));

  public getProcessRuns = (id: number, state?: string, page: number = 1, pageSize: number = 10) => (
    this.instance.get<IGetFinishedProcessRunsResponse>(`/processes/${id}/runs?state=${state}&page=${page}&pageSize=${pageSize}`));

  public getProcessRunSchedules = (id: number) => (
    this.instance.get<IGetScheduledProcessesResponse>(`/processes/${id}/schedules`));

  public updateProcess = (id: number, body: Partial<UpdateProcessDto>) => (
    this.instance.patch<IProcess>(`/processes/${id}`, body));

  public getActiveProcesses = () => (
    this.instance.get<IGetActiveProcessesResponse>('/active-processes'));

  public getFinishedProcesses = (params: IGetFinishedProcessesQueries) => (
    this.instance.get<IGetFinishedProcessesResponse>('/finished-processes', { params }));

  public getFinishedProcessesFilters = () => (
    this.instance.get<IGetFinishedProcessesFiltersResponse>('/finished-processes/filters'));

  public async getProcessRun(id: number) {
    return await this.instance.get(`/user-processes/${id}`);
  }

  public async getRunLogs(
    proceesId: number,
    processRunId: number,
    stepRunId: number | null,
    continuationToken: string | null,
    options?: { signal?: AbortSignal }
  ) {
    const queryParams = new URLSearchParams();
    if (stepRunId) {
      queryParams.append('stepRunId', stepRunId.toString());
    }
    if (continuationToken) {
      queryParams.append('continuationToken', continuationToken);
    }

    let url = `/processes/${proceesId}/runs/${processRunId}/logs?${queryParams.toString()}`;

    return await this.instance.get<IProcessRunLogsPage>(url, { signal: options?.signal });
  }

  public terminateProcessRun = (processId: number, runId: number) => (
    this.instance.post<IEmptyResponse>(`/processes/${processId}/runs/${runId}/stop`));

  public archiveProcessRun = (processId: number, runId: number) => (
    this.instance.put<IEmptyResponse>(
      `/processes/${processId}/runs/${runId}/archive`,
      { action: 'archive' },
    ));

  public restoreProcessRun = (processId: number, runId: number) => (
    this.instance.put<IEmptyResponse>(
      `/processes/${processId}/runs/${runId}/archive`,
      { action: 'restore' },
    ));

  public async getDownloadUrl(body: IDownloadUrlBody) {
    return await this.instance.post<IDownloadUrlResponse>('/user-processes/download-artifact', body);
  }

  public getProcessIssues = (processId: string) => (
    this.instance.get(`/process/${processId}/issues`)
  );

  public updateOrganizationEmailDomains = (id: number, body: { allowedEmailDomains: string[] }) => (
    this.instance.patch<IOrganization>('/domains', { organizationId: id, ...body }));

  public createOrganization = (body: Partial<IOrganization>) => (
    this.instance.post<IOrganization>('/organizations', body));

  public createUser = (body: IUserCreateBody) => (
    this.instance.post<IUser>('/users', body));

  public addAllowedEmailDomainToOrganization = (organizationID: number, emailDomain: string) => (
    this.instance.post<IOrganization>('/organizations/' + organizationID + '/allowedEmailsDomains', { allowedEmailDomain: emailDomain }));

  public deleteAllowedEmailDomainFromOrganization = (organizationID: number, emailDomain: string) => (
    this.instance.delete<IOrganization>('/organizations/' + organizationID + '/allowedEmailsDomains', { data: { domain: emailDomain } }));

  public updateOrganization = (id: number, body: Partial<IOrganization>) => (
    this.instance.patch<IOrganization>('/organizations/' + id, body));

  public async getOrganizationAnalyticsDashboardEmbedUrl(organizationId: number): Promise<IAnalyticsDashboard> {
    return await this.instance.get(`/organizations/${organizationId}/analytics`);
  }

  public async getOrganizationAnalyticsDigestSchedules(organizationId: number): Promise<IAnalyticsDigestSchedule[]> {
    return await this.instance.get(`organizations/${organizationId}/analytics/schedules`);
  }

  public async createOrganizationAnalyticsDigestSchedule(organizationId: number, body: ICreateAnalyticsDigestSchedule): Promise<IAnalyticsDigestSchedule> {
    return await this.instance.post(`organizations/${organizationId}/analytics/schedules`, body);
  }

  public async updateOrganizationAnalyticsDigestScheduleSubscribers(
    organizationId: number,
    scheduleId: number,
    subscribers: number[]
  ): Promise<IAnalyticsDigestSchedule> {
    return await this.instance.patch(
      `organizations/${organizationId}/analytics/schedules/${scheduleId}/subscribers`,
      { subscribers }
    );
  }

  public async deleteOrganizationAnalyticsDigestSchedule(organizationId: number, scheduleId: number): Promise<void> {
    await this.instance.delete(`organizations/${organizationId}/analytics/schedules/${scheduleId}`);
  }

  public getWorkReport = (id: string | string[] | undefined) => (
    this.instance.get(`/user-processes/${id}/reports/work`)
  )

  public getRunTimeline = (id: string) => (
    this.instance.get(`/v2/user-processes/${id}/run-timeline`)
  )

  public getRunTimelineCSV = (id: string) => (
    this.instance.get(`/v2/user-processes/${id}/run-timeline`, {
      headers: {
        Accept: 'text/csv',
      },
    })
  )
}

export default MainApiProtected;
