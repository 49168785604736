import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { ScheduledProcess } from '@/interfaces/scheduled-process';

export interface LegacyScheduledProcessesState {
  isPending: boolean;
  isRejected: boolean;
  processes: (ScheduledProcess & { isPending: boolean })[];
  shouldMonitorSchedules: boolean
}

const initialState: LegacyScheduledProcessesState = {
  isPending: false,
  isRejected: false,
  processes: [],
  shouldMonitorSchedules: false
};

export class ScheduledProcessesReducer extends ImmerReducer<LegacyScheduledProcessesState> {
  public setIsPending() {
    this.draftState.isPending = true;
    this.draftState.isRejected = false;
  }

  public setProcesses(processes: ScheduledProcess[]) {
    processes.sort((a, b) => a.id - b.id);

    this.draftState.isPending = false;
    this.draftState.processes = processes.map(p => ({ ...p, isPending: false }));
  }

  public setIsRejected() {
    this.draftState.isPending = false;
    this.draftState.isRejected = true;
  }

  public setShouldMonitorSchedules(trueOrFalse: boolean) {
    this.draftState.shouldMonitorSchedules = trueOrFalse
  }

  public setScheduledRunIsPending(scheduleId: number, isPending: boolean) {
    const schedule = this.draftState.processes.find(p => p.id === scheduleId);
    if (schedule) {
      schedule.isPending = isPending;
    }
  }

  public setScheduledRun(scheduledRun: ScheduledProcess) {
    const index = this.draftState.processes.findIndex(p => p.id === scheduledRun.id);

    if (index !== -1) {
      const origSchedule = this.draftState.processes[index];
      this.draftState.processes[index] = { ...origSchedule, ...scheduledRun };
    }
  }
}

export default createReducerFunction(ScheduledProcessesReducer, initialState);
