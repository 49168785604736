import { Grid, Breadcrumbs, Link, Typography } from '@mui/material'
import { useRouter } from 'next/router';
import React, { useMemo } from 'react'

const BreadCrumb = () => {
  const router = useRouter();

  const selectedPage = useMemo(() => {
    const path = router.pathname.split("/")[2];
    if(path === "processes") return "Processes"
    if(path === "organizations") return "Organizations"
    if(path === "users") return "Users"
    return undefined
  }, [router.pathname]);

  const basePath = useMemo(() => {
    const base = router.pathname.split("/");
    const path = router.pathname.split("/")[2];
    if(!path) return undefined
    return path.charAt(0).toUpperCase() + path.slice(1);
  }, [router.pathname]);

  const createOrEdit = useMemo(() => {
    const path = router.pathname.split("/")[3];
    if(!path && !basePath) return undefined
    switch(path) {
      case "create": return "Create"
      case "[id]": return "Edit"
      default: {
        return undefined;
      }
   }
  }, [router.pathname]);

  const isAdminPage = router.asPath.includes("/admin");

  if(!isAdminPage) return (<></>);
  return (
    <Grid item xs={12} sx={{marginBottom: "20px"}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/admin">
          Admin
        </Link>
        {selectedPage &&
          <Link underline="hover" color="inherit" href={"/admin/" + selectedPage.toLowerCase()}>
            {selectedPage}
          </Link>
        }
        {createOrEdit && <Typography color="text.primary">{createOrEdit}</Typography>}
      </Breadcrumbs>
    </Grid>
  )
}

export default BreadCrumb
