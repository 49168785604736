import { logoutAsync } from '@/store/actions/authenticate';
import { Button, Dialog, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

import { useIdleTimer } from 'react-idle-timer'
import Countdown from './Countdown';
import { AppDispatch } from '@/store';

const INACTIVE_MINUTES = 60;

const InactiveMessageModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * INACTIVE_MINUTES,
    debounce: 500
  })

  const [idleTimeExceeded, setIdleTimeExceeded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if(getRemainingTime() === 0) setIdleTimeExceeded(true);
    }, 1000)

    return () => {
      clearInterval(interval);
    }
  }, [])

  if(!idleTimeExceeded) return (<></>)

  return (
    <Dialog open={idleTimeExceeded}>
      <Grid container spacing={4} rowSpacing={4}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
          <Typography variant="h2" textAlign="center">You still there?</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant='body1' textAlign="center">We detected you&apos;ve been inactive for 90 minutes, would you like to stay logged in?</Typography>
          <Typography variant='body1' textAlign="center">
            Otherwise you will be logged out in{" "}
            <Countdown minutes={5} onCountdownEnds={() => dispatch(logoutAsync())}/>
          </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button onClick={() => setIdleTimeExceeded(false)}>Stay logged in</Button>
          <Button onClick={() => dispatch(logoutAsync())}>Logout</Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default InactiveMessageModal;
