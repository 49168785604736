export const isEnabled = (value: string | undefined): boolean => {
  return value === "true";
};

export const isProcessDemoVideoEnabled = (): boolean => {
  return isEnabled(process.env["NEXT_PUBLIC_PROCESS_DEMO_VIDEO_ENABLED"]);
};

export const isAnalyticsEnabled = (): boolean => {
  return isEnabled(process.env["ANALYTICS_ENABLED"]);
};
