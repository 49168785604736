import { userActions } from '@/store/actions/user';
import { selectDrawerMenuOpen } from '@/store/selectors/user';
import { Drawer, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from './Navigation';

const DrawerMenu = () => {
  const dispatch = useDispatch();

  const isDrawerMenuOpen = useSelector(selectDrawerMenuOpen);

  return (
    <Drawer anchor='left' open={isDrawerMenuOpen} onClose={() => dispatch(userActions.setDrawerMenuOpen(false))}>
      <Grid item xs={12} sx={{background: "#EBEEF1", borderRadius: "15px", padding: "20px"}}  display="flex" flexDirection="column">
        <Navigation />
      </Grid>
    </Drawer>
  );
};

export default DrawerMenu;
