import { useEffect, useRef, useState } from 'react';

export const useCountdown = (minutes: number) => {
  const expirationDate = new Date();
  const countDownDate = (minutes * 60) * 1000;
  expirationDate.setTime(expirationDate.getTime() + countDownDate);

  const [countDown, setCountDown] = useState(countDownDate);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(expirationDate.getTime() - (new Date().getTime()));
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return {minutes: minutes > 0 ? minutes : 0, seconds: seconds > 0 ? seconds : 0};
};