import { type NextRouter } from 'next/router';

export const continuationPath = (router: NextRouter) => {
  const url = decodeURI(router.query?.originalURL?.toString() ?? '/processes');
  return url
    .replace(/javascript:/gi, '')
    .replace(/data:/gi, '')
    .replace(/vbscript:/gi, '')
    .replace(/http(s)?:\/\/.*/gi, '')
    .replace(/www\.*/gi, '');
}
