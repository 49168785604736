import { createReducerFunction, ImmerReducer } from 'immer-reducer';

export interface ILogsState {
  isPending: boolean;
  logsMap: Record<string, string[]>;
  logs: string[];
  continuationToken: string | null;
  requestId: string | null;
  source: string | null;
}

export const initialState: ILogsState = {
  isPending: false,
  logsMap: {},
  logs: [],
  continuationToken: null,
  requestId: null,
  source: null,
};

export class LogsReducer extends ImmerReducer<ILogsState> {
  setIsPending(isPending: boolean) {
    this.draftState.isPending = isPending;
  }

  setRequestId(requestId: string | null) {
    this.draftState.requestId = requestId
  }

  addLogs(logs: string[], continuationToken: string | null, source: string) {
    if (source !== this.draftState.source) {
      /*
      This prevents logs from different sources from being mixed.
      For example, some logs are loaded during the process run,
      and after the process is completed, the user clicks `load more`.
       */
      this.reset();
    }

    if (this.draftState.continuationToken === null) {
      this.draftState.logsMap = { initial: logs };
    } else {
      this.draftState.logsMap = {
        ...this.draftState.logsMap,
        [this.draftState.continuationToken]: logs
      };
    }

    this.draftState.logs = Object.values(this.draftState.logsMap).flat();
    this.draftState.source = source;
    this.draftState.continuationToken = continuationToken;
  }

  reset() {
    this.draftState = { ...initialState };
  }
}

export default createReducerFunction(LogsReducer, initialState);
