import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from 'axios';

import TokensLocalStorage from '@/local-storage/TokensLocalStorage';

import HttpClient from './HttpClient';

export enum APIEvent {
  Unauthorized = 'unauthorized',
}

abstract class HttpClientProtected extends HttpClient {
  baseUrl: string;

  readonly eventTarget: EventTarget;

  public constructor(baseURL: string) {
    super(baseURL);
    this.baseUrl = baseURL;
    this.eventTarget = new EventTarget();

    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest);
    this.instance.interceptors.response.use(
      (response) => response,
      this.handleExpiredToken
    );
  };

  private handleRequest = (config: InternalAxiosRequestConfig) => {
    const tokensLocalStorage = TokensLocalStorage.getInstance();

    const accessToken = tokensLocalStorage.getAccessToken();

    const token = `Bearer ${accessToken}`;

    const modifiedConfig = config;

    modifiedConfig.headers = modifiedConfig.headers || {};
    modifiedConfig.headers.Authorization = token;

    return config;
  };

  private handleExpiredToken = async (error: AxiosError) => {
    /*
    * When the token is expired we get a 403
    * So only refresh the token on 403 error
    */
    if (error?.response?.status !== 403) {
      return Promise.reject(error);
    }

    const refreshToken = TokensLocalStorage.getInstance().getRefreshToken();

    if (error.config?.url === "/verify" || !refreshToken) {
      this.dispatchAndThrowUnauthorized();
    }

    let refreshTokenResponse: AxiosResponse;

    try {
      refreshTokenResponse = await axios.post(`${this.baseUrl}/refresh-token`, {
        refreshToken,
      }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        this.dispatchAndThrowUnauthorized();
      }

      throw error;
    }

    TokensLocalStorage.getInstance().setAccessToken(refreshTokenResponse.data.accessToken);
    TokensLocalStorage.getInstance().setRefreshToken(refreshTokenResponse.data.refreshToken);

    const modifiedConfig = error.config || {} as AxiosRequestConfig;
    modifiedConfig.headers = modifiedConfig.headers || {};
    modifiedConfig.headers.Authorization = `Bearer ${refreshTokenResponse.data.accessToken}`;

    let originalRequestResponse: AxiosResponse;

    try {
      originalRequestResponse = await axios.request(modifiedConfig);
    } catch (error) {
      if (
        isAxiosError(error)
        && (error.response?.status === 401 || error.response?.status === 403)
      ) {
        this.dispatchAndThrowUnauthorized();
      }

      throw error;
    }

    return originalRequestResponse.data;
  };

  private dispatchAndThrowUnauthorized = () => {
    this.eventTarget.dispatchEvent(new Event(APIEvent.Unauthorized));
    throw new Error("unauthorized");
  }
}

export default HttpClientProtected;
