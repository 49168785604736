import { IOrganization } from '@/interfaces/organization';
 import { IProcess } from '@/interfaces/process';
 import { createReducerFunction, ImmerReducer } from 'immer-reducer';

 export interface IOrganizationsState {
   isPending: boolean;
   isRejected: boolean;
   organizations: IOrganization[];
 }

 const initialState: IOrganizationsState = {
   isPending: true,
   isRejected: false,
   organizations: [],
 };

 export class OrganizationsReducer extends ImmerReducer<IOrganizationsState> {
   public setIsPending() {
    this.draftState.isPending = true;
     this.draftState.isRejected = false;
   }

   public setOrganizations(organizations: any[]) {
     this.draftState.isPending = false;
     this.draftState.organizations = organizations;
   }

   public setIsRejected() {
     this.draftState.isPending = false;
     this.draftState.isRejected = true;
   }
 }

 export default createReducerFunction(OrganizationsReducer, initialState);
