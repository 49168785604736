import React, { useEffect } from 'react'
import { useCountdown } from './hooks/useCountdown';

type Props = {
  minutes: number
  onCountdownEnds?: () => void;
}

const Countdown = ({minutes: minutesProps, onCountdownEnds}: Props) => {
  const {minutes, seconds} = useCountdown(minutesProps);
  
  useEffect(() => {
    if(minutes + seconds === 0 && onCountdownEnds) onCountdownEnds()
  }, [minutes, seconds])
  
  return (
    <>{minutes} : {seconds.toLocaleString("en-US", {minimumIntegerDigits: 2})}</>
  )
}

export default Countdown