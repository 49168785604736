import { IUser } from '@/interfaces/user';

declare global {
  interface Window {
    heap:any;
  }
}

export const identify = (user: IUser) => {
  try {
    if (window.heap?.identify) {
      window.heap.identify(user.id);
      window.heap.addUserProperties({
        ...user,
        roleID: user.role.id,
        roleName: user.role.name,
        organizationID: user.organization.id,
        organizationName: user.organization.name,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const clear = () => {
  try {
    window.heap.resetIdentity();
  } catch (e) {
    console.log(e);
  }
};
