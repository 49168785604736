import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { IProcess } from '@/interfaces/process';

interface IStartProcessState {
  isPending: boolean;
  isRejected: boolean;
  process: null | IProcess;
  error?: string;
}

const initialState: IStartProcessState = {
  isPending: false,
  isRejected: false,
  process: null
};

export class StartProcessReducer extends ImmerReducer<IStartProcessState> {
  public setIsPending() {
    this.draftState.isPending = true;
    this.draftState.isRejected = false;
  }

  public setIsRejected(e: any) {
    this.draftState.isPending = false;
    this.draftState.isRejected = true;

    const msg = e.response?.data?.msg || "";
    const instanceId = e.response?.data?.instanceId || "";

    let errorMessage = "Something went wrong."

    if (msg.includes("403")) {
      errorMessage = "Request cannot be processed. Unable to connect."
    } else if (msg.includes("Error scheduling process")) {
      errorMessage = "Sorry, we can’t schedule your bot in the past. Please select a future start time."
    } else if (e.response?.status === 400 && e.response?.data?.msg) {
      errorMessage = e.response?.data?.msg + '.';
    }

    if (instanceId) {
      errorMessage += ` Instance ID: ${instanceId}`
    }

    this.draftState.error = errorMessage;
  }

  public setProcess(process: IProcess) {
    this.draftState.process = process;
  }

  public reset() {
    this.draftState.process = initialState.process;
    this.draftState.isPending = initialState.isPending;
    this.draftState.isRejected = initialState.isRejected;
  }
}

export default createReducerFunction(StartProcessReducer, initialState);
