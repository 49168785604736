import { selectIsFullScreenLoading } from '@/store/selectors/user'
import { Dialog, Grid, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux'
import RefreshingLoader from './RefreshingLoader';

const LoadingModal = () => {
  const fullScreenLoading = useSelector(selectIsFullScreenLoading);
  
  if(!fullScreenLoading) return (<></>)
  return (
    <Dialog open={fullScreenLoading}>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" sx={{minWidth: "20vw", marginTop: "16px"}}>
        <RefreshingLoader/>
      </Grid>
    </Dialog>
  )
}

export default LoadingModal