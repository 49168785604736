export enum ROLE {
  EMPLOYEE = 1,
  MANAGER = 2,
  ADMIN = 3,
  SUPPORT = 4
}

export interface IRole {
  id: ROLE;
  name: string;
}
