import { userActions } from "@/store/actions/user";
import TopBarTab from "./TopBarTab";
import router from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectHomeTab } from "@/store/selectors/user";
import { AppDispatch } from "@/store";
import { IUser } from "@/interfaces/user";
import { isInternalUser } from "@/utils/user";
import { isAnalyticsEnabled } from "@/utils/feature-flag";

const showAnalyticsTab = (user: IUser | null) => {
  if (!isAnalyticsEnabled() || !user) {
    return false;
  }

  const hasAnalyticsEmbedDashboard = Boolean(user.organization.analyticsEmbedDashboardId);

  return isInternalUser(user) || hasAnalyticsEmbedDashboard;
};


const Navigation = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isAdminPage = router.asPath.includes("/admin");
  const user = useSelector(selectUser);
  const tab = useSelector(selectHomeTab);

  const onClickHomeTab = (value: number) => {
    dispatch(userActions.setHomeTab(value))
  }

  const onSelectTab = (value: number) => {
    onClickHomeTab(value);
  }

  return (
    !isAdminPage ? <>
    <TopBarTab
      label='AI Agents'
      onSelectTab={onSelectTab}
      value={0}
      selectedTab={tab}
      route="/processes"
    />
    <TopBarTab
      label='Active Runs'
      onSelectTab={onSelectTab}
      value={1}
      selectedTab={tab}
      route="/process-runs/active"
    />
    <TopBarTab
      label='Scheduled Runs'
      onSelectTab={onSelectTab}
      value={2}
      selectedTab={tab}
      route="/process-runs/scheduled"
    />
    <TopBarTab
      label='Finished Runs'
      onSelectTab={onSelectTab}
      value={3}
      selectedTab={tab}
      route="/process-runs/finished"
    />
    {showAnalyticsTab(user) &&
      <TopBarTab
        label='Analytics'
        onSelectTab={onSelectTab}
        value={4}
        selectedTab={tab}
        route="/analytics"
      />
    }
    </>
    :
    <>
    <TopBarTab
      label='Processes'
      onSelectTab={onSelectTab}
      value={0}
      selectedTab={tab}
      route="/admin/processes"
    />
    <TopBarTab
      label='Organizations'
      onSelectTab={onSelectTab}
      value={1}
      selectedTab={tab}
      route="/admin/organizations"
    />
    <TopBarTab
       label='Users'
       onSelectTab={onSelectTab}
       value={2}
       selectedTab={tab}
       route="/admin/users"
     />
    </>
  );
};

export default Navigation;
