import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

const bugsnagApiKey = process.env.BUGSNAG_API_KEY;
const appVersion = process.env.APP_VERSION;
const releaseStage = process.env.STAGE;

export const start = () => {
  if (!bugsnagApiKey) {
    console.warn('BUGSNAG_API_KEY is not set, skipping Bugsnag initialization');
    return;
  }

  Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    appVersion: appVersion,
    releaseStage: releaseStage,
  });
  BugsnagPerformance.start({
    apiKey: bugsnagApiKey,
    appVersion: appVersion,
    releaseStage: releaseStage,
  });
};

/**
 * The BugSnag ErrorBoundary allows to capture React render errors in the application.
 * https://docs.bugsnag.com/platforms/javascript/react/#capturing-react-render-errors
 */
export const getErrorBoundaryComponent = () => {
  if (Bugsnag.isStarted()) {
    return Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  }

  return null;
};
