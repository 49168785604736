import { userActions } from '@/store/actions/user'
import { selectVideoModalURL } from '@/store/selectors/user'
import { Button, CardMedia, Dialog, Grid, Typography } from '@mui/material'
import React, {  } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const VideoModal = () => {
  const dispatch = useDispatch();

  const url = useSelector(selectVideoModalURL);
  const open = url !== undefined;

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <Grid container spacing={4} rowSpacing={4}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <CardMedia
              component="iframe"
              src={url}
              allow="autoPlay"
              sx={{width: "100%", height: "70vh"}}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button onClick={() => dispatch(userActions.setVideoModalURL(undefined))}>Close</Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default VideoModal;