import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';

import MainApi from '@/api/MainApi';
import MainApiProtected from '@/api/MainApiProtected';

import { AuthenticateActions } from './actions/authenticate';
import { userActions, UserActions } from './actions/user';
import { AvailableProcessesActions } from './actions/available-processes';
import { ScheduledProcessesActions } from './actions/scheduled-processes';
import { StartProcessActions } from './actions/start-process';
import { ActiveProcessesActions } from './actions/active-processes';
import { FinishedProcessesActions } from './actions/finished-processes';
import { FiltersActions } from './actions/filters';
import { ProcessDetailsActions } from './actions/process-details';
import { DeleteScheduledRunActions } from './actions/delete-scheduled-run';

import authenticateReducer from './reducers/authenticate';
import userReducer from './reducers/user';
import availableProcessesReducer from './reducers/available-processes';
import scheduledProcessesReducer from './reducers/scheduled-processes';
import deleteScheduledRunReducer from './reducers/delete-scheduled-run';
import startProcessReducer from './reducers/start-process';
import activeProcessesReducer from './reducers/active-processes';
import finishedProcessesReducer from './reducers/finished-processes';
import filtersReducer from './reducers/filters';
import processDetailsReducer from './reducers/process-details';
import { OrganizationsActions } from './actions/organizations';
import organizationsReducer from './reducers/organizations';
import usersReducer from "./reducers/users";
import { UsersActions } from './actions/users';
import analyticsDigestSchedulesReducer from './reducers/analytics-digest-schedules';
import { AnalyticsDigestSchedulesActions } from './actions/analytics-digest-schedules';
import logsReducer from './reducers/logs';
import { LogsActions } from './actions/logs';
import { APIEvent } from '@/api/HttpClientProtected';

export const api = {
  mainApi: MainApi.getInstance(),
  mainApiProtected: MainApiProtected.getInstance(),
};

const rootReducer = combineReducers({
  authenticateReducer,
  userReducer,
  availableProcessesReducer,
  scheduledProcessesReducer,
  deleteScheduledRunReducer,
  startProcessReducer,
  activeProcessesReducer,
  finishedProcessesReducer,
  filtersReducer,
  processDetailsReducer,
  organizationsReducer,
  usersReducer,
  analyticsDigestSchedulesReducer,
  logsReducer,
});

const composeEnhancers = compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk.withExtraArgument(api)),
);

const store = createStore(rootReducer, enhancer);

api.mainApiProtected.eventTarget.addEventListener(APIEvent.Unauthorized, () => {
  store.dispatch(userActions.setIsSessionValid(false));
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof rootReducer>;
export type Actions =
  | AuthenticateActions
  | UserActions
  | AvailableProcessesActions
  | ScheduledProcessesActions
  | DeleteScheduledRunActions
  | StartProcessActions
  | ActiveProcessesActions
  | FinishedProcessesActions
  | FiltersActions
  | ProcessDetailsActions
  | OrganizationsActions
  | UsersActions
  | AnalyticsDigestSchedulesActions
  | LogsActions;

  export default store;
