import React from 'react';
import { Grid } from '@mui/material';
import Image from "next/legacy/image";

const SplashScreen = () => {
  return (
    <Grid display="flex" minHeight="100vh" justifyContent="center" alignItems="center" sx={{background: "white"}}>
      <Image src="/assets/logo.svg" alt="logo" width={200} height={200} />
    </Grid>
  );
};

export default SplashScreen;
