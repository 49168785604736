import { createSelector, Selector } from 'reselect';

import { State } from '@/store';

import { IUser } from '@/interfaces/user';
import { ROLE } from '@/interfaces/role';

const selectUserState = (state: State) => state.userReducer;

export const selectIsUserLoggedIn: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ isLoggedIn }) => isLoggedIn,
);

export const selectIsUserSessionValid: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ isSessionValid }) => isSessionValid,
);

export const selectIsUserPending: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ isPending }) => isPending,
);

export const selectIsFullScreenLoading: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ fullScreenLoading }) => fullScreenLoading,
);

export const selectUser: Selector<State, IUser | null> = createSelector(
  selectUserState,
  ({ user }) => user,
);

export const selectIsUserSupport: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.SUPPORT;
  },
);

export const selectIsUserAllowedToSeePrivateArtifacts: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.ADMIN || id === ROLE.SUPPORT;
  },
);

export const selectIsUserAllowedToSeeAllProcesses: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.ADMIN || id === ROLE.SUPPORT;
  },
);

export const selectIsUserAllowedToSeeAdminCodes: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.ADMIN || id === ROLE.SUPPORT;
  },
);

export const selectIsUserAllowedToSeeOrganizationName: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.ADMIN || id === ROLE.SUPPORT || id == ROLE.MANAGER;
  },
);

export const selectIsUserAllowedToSeeOrganizationFilter: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.ADMIN || id === ROLE.SUPPORT;
  },
);

export const selectIsUserAllowedToSeeProcessExecutedBy: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.ADMIN || id === ROLE.SUPPORT || id == ROLE.MANAGER;
  },
);

export const selectIsUserAdmin: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { id } = user.role;

    return id === ROLE.ADMIN;
  },
);

export const selectIsBetaUser: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;

    const { inBetaCohort } = user;

    return inBetaCohort == 'true' ? true : false;
  },
);

export const selectHomeTab: Selector<State, number> = createSelector(
  selectUserState,
  ({ homeTab }) => homeTab,
);

export const selectVideoModalURL: Selector<State, string | undefined> = createSelector(
  selectUserState,
  ({ videoModalURL }) => videoModalURL,
);

export const selectDrawerMenuOpen: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ drawerMenuOpen }) => drawerMenuOpen,
);

export const selectCanUserManageProcessRun: Selector<State, boolean> = createSelector(
  selectUserState,
  ({ user }) => {
    if (!user) return false;
    return user.role.id === ROLE.ADMIN || user.role.id === ROLE.SUPPORT;
  },
);
