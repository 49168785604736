import { IAnalyticsDigestSchedule } from "@/interfaces/organization";
import { ImmerReducer, createReducerFunction } from "immer-reducer";

export interface Schedule extends IAnalyticsDigestSchedule {
  isNew?: boolean;
  isSelected?: boolean;
}

export type AnalyticsDigestSchedulesState = {
  initializing: boolean;
  loading: boolean;
  schedules: Schedule[];
  error: Error | null;
};

const initialState: AnalyticsDigestSchedulesState = {
  initializing: true,
  loading: false,
  schedules: [],
  error: null
};

export class AnalyticsDigestSchedulesReducer extends ImmerReducer<AnalyticsDigestSchedulesState> {
  setLoading(loading: boolean) {
    this.draftState.loading = loading;
  }

  setInitializing(value: boolean) {
    this.draftState.initializing = value;
  }

  setSchedules(schedules: Schedule[]) {
    this.draftState.schedules = schedules;
  }

  setError(error: Error) {
    this.draftState.error = error;
  }
}

export default createReducerFunction(AnalyticsDigestSchedulesReducer, initialState);
